const imgLogoStyle = {
  position: "fixed",
  //float: "left",
  top: "10px",
  left: "20px",
  right: "10px",
  //margin: "0px 15px 15px 0px",
  width: "190",
  height: "60",
  //padding: "10px 10px 0px 10px",
  //display: "inline"
};


export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top navbar-expand-sm'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Menu</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            <img
              src='logos/logo-margin.png'
              style={imgLogoStyle}
              alt='EuroSTA'
              className="d-inline-block align-top"
            />{' '}
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse navbar-toggleable-sm'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                Chi siamo
              </a>
            </li>
            <li>
              <a href='#process' className='page-scroll'>
                Processo
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Servizi
              </a>
            </li>
            <li>
              <a href='#partnership' className='page-scroll'>
                Enti Convenzionati
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contatti
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
