/*export const Partnership = (props) => {
  return (
    <div id='partnership' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Ci hanno scelti</h2>
          <p>
            Questi sono gli enti convenzionati che hanno scelto di affidarsi ai servizi di EuroSTA 
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 partnership'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='partnership-img'/>
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.description}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}*/

export const Partnership = (props) => {
  return (
    <div id='partnership' className='text-center'>
      <div className='container supreme-container-blur'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Ci hanno scelti</h2>
          <p>
            Diversi sono gli Istituti che hanno scelto di affidarsi ad EuroSTA 
          </p>
        </div>
        <div id="row">
          <div className="col-md-12 col-sm-12 partnership">
            <div className="thumbnail">
              {' '}
              <img src="img/extra/wallet.svg" alt="logo" className="partnership-img"/>            
              <div className="caption">
                <h4>Banche e Mediatori</h4>
                <p>Lavoriamo al fianco dei principali Istituti di credito, mediatori creditizi ed Agenti in attività finanziaria per la richiesta di certificati e la verifica documentale in ambito antifrode</p>
              </div>
            </div>
          </div>
          {/*
          <div className="col-md-6 col-sm-6 partnership">
            <div className="thumbnail">
              {' '}
              <img src="img/extra/assistant.svg" alt="logo" className="partnership-img"/>
              <div className="caption">
                <h4>Patronato</h4>
                <p>Uno dei patronati più importanti a livelli nazionali collabora con noi per la ricezione e l'invio di documentazione</p>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  )
}