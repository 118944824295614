import { useState } from 'react'
import emailjs from 'emailjs-com'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFingerprint } from '@fortawesome/free-solid-svg-icons'

const initialState = {
  nome: '',
  cognome: '',
  ragione_sociale: '',
  email: '',
  messaggio: '',
  formRes: false,
  formRet: ''
}

const ContactRes = () => {
  if(this.formRet === "OK"){
    console.log(this.formRet)
    return (
      <div id='success' className="alert alert-success">
        <strong>Operazione avvenuta!</strong> La tua Email è stata recapitata con successo
      </div>
    )
  } else {
    console.log(this.formRet)
    return (
      <div id='failure' className="alert alert-failure">
        <strong>Errore!</strong> Si è verificato un errore: {this.formRet}
      </div>
    )
  }
}

export const Contact = (props) => {
  const [{ nome, cognome, ragione_sociale, email, messaggio, formRes, formRet }, setState] = useState(initialState)

  const handleChangeNome = (e) => {
    const { nome, value } = e.target
    setState((prevState) => ({ ...prevState, [nome]: value }))
  }
  const handleChangeCognome = (e) => {
    const { cognome, value } = e.target
    setState((prevState) => ({ ...prevState, [cognome]: value }))
  }
  const handleChangeRagioneSociale = (e) => {
    const { ragione_sociale, value } = e.target
    setState((prevState) => ({ ...prevState, [ragione_sociale]: value }))
  }
  const handleChangeEmail = (e) => {
    const { email, value } = e.target
    setState((prevState) => ({ ...prevState, [email]: value }))
  }
  const handleChangeMessaggio = (e) => {
    const { messaggio, value } = e.target
    setState((prevState) => ({ ...prevState, [messaggio]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(nome, cognome, ragione_sociale, email, messaggio);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          
          setState((prevState) => ({ ...prevState, [formRet]: result.text }));
          setState((prevState) => ({ ...prevState, [formRes]: true }));
          console.log(formRet, formRes);
          alert("Email inviata con successo!", result.text);
          
          clearState()
        },
        (error) => {
          console.log(error.text);

          setState((prevState) => ({ ...prevState, [formRet]: error.text }));
          setState((prevState) => ({ ...prevState, [formRes]: true }));
          alert("Errore nell'invio dell'email. Error: ", error.text);
        }
      )
  }

  return (
    <div>
      <div id='contact'>
        <div className='container supreme-container-blur'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contattaci</h2>
                <p>
                  Se vuole contattarci, compili i campi form qui sotto! Riceverà una risposta il prima possibile
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='nome'
                        name='nome'
                        className='form-control'
                        placeholder='Nome'
                        required
                        onChange={handleChangeNome}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='cognome'
                        name='cognome'
                        className='form-control'
                        placeholder='Cognome'
                        required
                        onChange={handleChangeCognome}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='ragione_sociale'
                        name='ragione_sociale'
                        className='form-control'
                        placeholder='Ragione Sociale'
                        required
                        onChange={handleChangeRagioneSociale}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>  
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Indirizzo e-mail'
                        required
                        onChange={handleChangeEmail}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='messaggio'
                    id='messaggio'
                    className='form-control'
                    rows='4'
                    placeholder='Messaggio'
                    required
                    //value={this.state.feedback}
                    onChange={handleChangeMessaggio}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div className='form-group form-check'>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    placeholder="privacy"
                    required
                  />
                  <label className="form-check-label" htmlFor="privacy">
                    Spuntare questa casella per accettare {' '}
                    <a data-toggle="modal" data-target="#privacyModal" href="#privacyModal">Termini e Condizioni</a>
                  </label>
                </div>
                <button type='submit' className='btn btn-custom btn-lg' value="Send">
                  Contattaci
                </button>
                {formRes ? <ContactRes /> : null}
              </form>
            </div>
          </div>
          
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Informazioni di Contatto</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Indirizzo
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefono
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fas fa-envelope'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fas fa-at'></i> PEC
                </span>{' '}
                {props.data ? props.data.pec : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <FontAwesomeIcon style={{marginRight: "10px"}} icon={faFingerprint}/>{' '} Partita IVA
                </span>{' '}
                {props.data ? props.data.piva : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center supreme-container'>
          <p>
             EuroSTA Servizi Tutele Antifrode - P.IVA 15566011001 - Via Antonio Pacinotti 73, 00146 Roma (RM) -{' '}
             <a data-toggle="modal" data-target="#privacyModal" href="#privacyModal">Termini e Condizioni</a>
          </p>
        </div>
      </div>
    </div>
  )
}
