export const ModalPrivacy = (props) => {
    return(
        <div className="modal fade" id="privacyModal" role="dialog" aria-labelledby="privacyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {props.data ? props.data.title : 'loading'}
              </h5>
            </div>
            <div className="modal-body">
            {props.data ? props.data.subtitle : 'loading'}
              {props.data
                ? props.data.paragrafi.map((par, index) => (
                    <div key={index}>
                      <h4 style={{color: "#777"}}>{par.title}</h4>
                      <p>{par.text}</p>
                    </div>
              ))
            : 'loading'}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal">Ho capito</button>
            </div>
          </div>
        </div>
        </div>
    )
}