import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

export const Process = (props) => {
  return (
    <div id='process' className='text-center'>
      <div className='container supreme-container-blur'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Semplicità nel processo</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-12 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{i+1}{'. '}{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
